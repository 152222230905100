import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import OverlayScrollbars from 'overlayscrollbars';

document.addEventListener('DOMContentLoaded', () => {
	OverlayScrollbars(document.querySelector('main'), {});
})

tippy('.gh', {
	 arrow: false,
	content: 'github'
})

tippy('.codepen', {
	 arrow: false,
	content: 'codepen.io'
})

tippy('.linkedin', {
	 arrow: false,
	content: 'linkedin'
})

